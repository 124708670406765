import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { data } from "./contents";
import service1 from "../../Assets/service1.png";
import service2 from "../../Assets/service2.png";
import service3 from "../../Assets/service3.png";
import service4 from "../../Assets/service4.png";
import service5 from "../../Assets/service5.png";
import mobservice1 from "../../Assets/services-mob-1.png";
import mobservice2 from "../../Assets/services-mob-2.png";
import mobservice3 from "../../Assets/services-mob-3.png";
import mobservice4 from "../../Assets/services-mob-4.png";
import mobservice5 from "../../Assets/services-mob-5.png";
import "./styles.css";

const Container = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  padding-top: 100px; /* Adjust based on your design */
  position: relative; /* Add position relative to the container */
  @media screen and (max-width: 768px) {
    padding-top: 70px; /* Adjust based on your design */
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  position: relative; /* Add position relative to the row */
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Col5 = styled.div`
  flex: 0 0 calc((5 / 12) * 100% - (40px * (7 / 12)));
  max-width: calc((5 / 12) * 100% - (40px * (7 / 12)));
  position: sticky;
  top: 100px; /* Adjust based on your design */
  align-self: flex-start;
  z-index: 1;
  @media screen and (max-width: 768px) {
    width:100%;
    flex: 0 0 calc(100%);
    max-width:100%;
    position: relative;
    top:0;
  }
`;

const Heading = styled.div`
  font-size: 31px;
  color: #0e1014;
  line-height: 38px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const Indicator = styled.div`
  font-size: 18px;
  color: #555c67;
  line-height: 19.8px;
  font-weight: 500;
  padding-top: 8px;
  @media screen and (max-width: 768px) {
    font-size: 11px;
  }
`;

const SubHeading = styled.div`
  font-size: 44px;
  color: #0e1014;
  font-weight: 400;
  padding-top: 50px;
  line-height: 56px;
  @media screen and (max-width: 768px) {
    font-family: Inter;
font-size: 28px;
font-weight: 400;
line-height: 33.6px;
letter-spacing: 0.005em;
padding-bottom:20px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  color: #555c67;
  line-height: 32px;
  @media screen and (max-width: 768px) {
font-size: 16px;
font-weight: 400;
line-height: 22px;
  }
`;

const ImageDiv = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: ${(props) => (props.index === 2 ? "flex-end" : "center")};
  width: 100%;
  height: 312px;
  border-radius: 24px;
  background: ${(props) => props.color};
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 148px;
    border-radius: 12px;
  }
`;

const OurServices = () => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const rowRefs = useRef([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;

      const indicesInView = rowRefs.current
        .filter((ref) => ref)
        .map((ref, index) => {
          const top = ref.getBoundingClientRect().top;
          return { index, top };
        })
        .filter(({ top }) => top > 0 && top < windowHeight);

      if (indicesInView.length > 0) {
        const newIndex = indicesInView[0].index;
        setCurrentIndex(newIndex);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const renderSmImage = (i) => {
    switch (i) {
      case 1:
        return mobservice1;
      case 2:
        return mobservice2;
      case 3:
        return mobservice3;
      case 4:
        return mobservice4;
      case 5:
        return mobservice5;
      default:
        return null;
    }
  };

  const renderMdImage = (i) => {
    switch (i) {
      case 1:
        return service1;
      case 2:
        return service2;
      case 3:
        return service3;
      case 4:
        return service4;
      case 5:
        return service5;
      default:
        return null;
    }
  };


  useEffect(() => {
    const handleScroll = () => {
      rowRefs.current.forEach((el, index) => {
        const rect = el.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isVisible) {
          el.classList.add("animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      console.log(window.innerWidth);

    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Container>
      <Row>
        <Col5>
          <Heading>{"OUR SERVICES"}</Heading>
          <Indicator>{`0${currentIndex + 1}/05`}</Indicator>
        </Col5>
        <div>
          {windowWidth > 768 ? data.map((item, index) => (
            <div
              key={index}
              ref={(el) => (rowRefs.current[index] = el)}
              style={{ paddingBottom: '100px' }}
            >
              <ImageDiv color={item.bgcolor} index={item.id}>
                <img
                  key={index}
                  ref={(el) => (rowRefs.current[index] = el)}
                  className={currentIndex === item.id - 1 ? (item.id === 2 ? "styled-services-image1" : `styled-services-image${index}`) : ""}
                  src={renderMdImage(item.id)}
                  alt={`service-${item.id}`}
                  style={item.style}
                />
              </ImageDiv>
              <SubHeading>{item.heading}</SubHeading>
              <Paragraph>{item.paragraph.map((p) =>
                <>
                  {p}
                  <br />
                  <br />
                </>)}</Paragraph>
            </div>
          )) :
            data.map((item, index) => (
              <div
                key={index}
                ref={(el) => (rowRefs.current[index] = el)}
                style={{ paddingBottom: '100px' }}
              >
                <ImageDiv color={item.bgcolor} index={item.id}>
                  <img
                    key={index}
                    ref={(el) => (rowRefs.current[index] = el)}
                    className={currentIndex === item.id - 1 ? (item.id === 2 ? "styled-services-image1" : `styled-services-image${index}`) : ""}
                    src={renderSmImage(item.id)}
                    alt={`service-${item.id}`}
                  />
                </ImageDiv>
                <SubHeading>{item.heading}</SubHeading>
                <Paragraph>{item.paragraph.map((p) =>
                  <>
                    {p}
                    <br />
                    <br />
                  </>)}</Paragraph>
              </div>
            ))}
        </div>
      </Row>
    </Container>
  );
};

export default OurServices;
