export const data = [
  {
    id: 1,
    desc: "Business with distributed data across locations",
    icon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.802 15.4904C35.877 7.02036 28.4887 3.20703 21.9987 3.20703C21.9987 3.20703 21.9987 3.20703 21.9804 3.20703C15.5087 3.20703 8.10204 7.00203 6.17704 15.472C4.03204 24.932 9.82538 32.9437 15.0687 37.9854C17.012 39.8554 19.5054 40.7904 21.9987 40.7904C24.492 40.7904 26.9854 39.8554 28.9104 37.9854C34.1537 32.9437 39.947 24.9504 37.802 15.4904ZM21.9987 24.6754C18.8087 24.6754 16.2237 22.0904 16.2237 18.9004C16.2237 15.7104 18.8087 13.1254 21.9987 13.1254C25.1887 13.1254 27.7737 15.7104 27.7737 18.9004C27.7737 22.0904 25.1887 24.6754 21.9987 24.6754Z"
          fill="url(#paint0_linear_7_185)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7_185"
            x1="21.9895"
            y1="3.20703"
            x2="21.9895"
            y2="44.8821"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 2,
    desc: "Difficult to collate your business data for analysis",
    icon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4169 9.09372C17.3154 8.92553 18.2164 9.37418 18.6084 10.1851C18.706 10.3322 18.7841 10.491 18.8408 10.6575C19.0899 14.5017 19.3555 18.2807 19.6046 22.0598C19.5989 22.4526 19.6606 22.8436 19.7872 23.2163C20.0851 23.9497 20.8266 24.4149 21.6301 24.3728L33.833 23.5909L33.9161 23.6235L34.1388 23.6395C34.5799 23.696 34.9912 23.8974 35.3035 24.2142C35.6678 24.5839 35.8675 25.0804 35.8589 25.5945C35.3777 32.6017 30.2404 38.4564 23.2491 39.9647C16.2579 41.4731 9.09105 38.2729 5.65833 32.1101C4.64896 30.3351 4.01112 28.3802 3.78223 26.3601C3.69345 25.7615 3.65458 25.1568 3.66601 24.552C3.68915 17.1009 8.99407 10.6696 16.4169 9.09372ZM23.6722 3.66948C32.052 3.92034 39.0554 10.0036 40.3247 18.1341C40.3327 18.1827 40.3327 18.2322 40.3247 18.2807L40.3224 18.5103C40.2949 18.8137 40.1703 19.103 39.9634 19.3354C39.7048 19.6258 39.3389 19.8031 38.9467 19.8282L24.8676 20.7567L24.6357 20.7613C24.2514 20.7425 23.8828 20.593 23.5958 20.3347C23.2513 20.0246 23.056 19.5864 23.0579 19.1278L22.1116 5.29838V5.07034C22.1288 4.68139 22.303 4.31514 22.5958 4.05235C22.8885 3.78956 23.2758 3.65181 23.6722 3.66948Z"
          fill="url(#paint0_linear_7_191)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7_191"
            x1="21.9974"
            y1="3.66797"
            x2="21.9974"
            y2="44.3266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 3,
    desc: "New business potential if you get your data online",
    icon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.1907 3.66797H11.7491C7.29406 3.66797 3.66406 7.29797 3.66406 11.753V23.6696V24.0363C3.66406 28.5096 7.29406 32.1213 11.7491 32.1213H18.7891C19.7974 32.1213 20.6224 32.9463 20.6224 33.9546V35.733C20.6224 36.7413 19.7974 37.5663 18.7891 37.5663H14.3524C13.6007 37.5663 12.9774 38.1896 12.9774 38.9413C12.9774 39.693 13.5824 40.3346 14.3524 40.3346H29.6607C30.4124 40.3346 31.0357 39.7113 31.0357 38.9596C31.0357 38.208 30.4124 37.5846 29.6607 37.5846H25.2241C24.2157 37.5846 23.3907 36.7596 23.3907 35.7513V33.973C23.3907 32.9646 24.2157 32.1396 25.2241 32.1396H32.2091C36.6824 32.1396 40.2941 28.5096 40.2941 24.0546V23.688V11.7713C40.2757 7.29797 36.6457 3.66797 32.1907 3.66797Z"
          fill="url(#paint0_linear_7_198)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7_198"
            x1="21.9791"
            y1="3.66797"
            x2="21.9791"
            y2="44.3266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 4,
    desc: "Lacking business intelligence,real time dashboards and reports",
    icon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.3307 40.3346H3.66406C2.9124 40.3346 2.28906 39.7113 2.28906 38.9596C2.28906 38.208 2.9124 37.5846 3.66406 37.5846H40.3307C41.0824 37.5846 41.7057 38.208 41.7057 38.9596C41.7057 39.7113 41.0824 40.3346 40.3307 40.3346Z"
          fill="url(#paint0_linear_7_205)"
        />
        <path
          d="M17.8724 7.33464V40.3346H26.1224V7.33464C26.1224 5.31797 25.2974 3.66797 22.8224 3.66797H21.1724C18.6974 3.66797 17.8724 5.31797 17.8724 7.33464Z"
          fill="url(#paint1_linear_7_205)"
        />
        <path
          d="M5.4974 18.3346V40.3346H12.8307V18.3346C12.8307 16.318 12.0974 14.668 9.8974 14.668H8.43073C6.23073 14.668 5.4974 16.318 5.4974 18.3346Z"
          fill="url(#paint2_linear_7_205)"
        />
        <path
          d="M31.1641 27.5013V40.3346H38.4974V27.5013C38.4974 25.4846 37.7641 23.8346 35.5641 23.8346H34.0974C31.8974 23.8346 31.1641 25.4846 31.1641 27.5013Z"
          fill="url(#paint3_linear_7_205)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7_205"
            x1="34.8307"
            y1="23.834"
            x2="34.8307"
            y2="42.1304"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_7_205"
            x1="34.8307"
            y1="23.834"
            x2="34.8307"
            y2="42.1304"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_7_205"
            x1="34.8307"
            y1="23.834"
            x2="34.8307"
            y2="42.1304"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_7_205"
            x1="34.8307"
            y1="23.834"
            x2="34.8307"
            y2="42.1304"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 5,
    desc: "Planning to migrate to Newgen technologies",
    icon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.6791 3.66797H14.3157C7.6424 3.66797 3.66406 7.6463 3.66406 14.3196V29.6646C3.66406 36.3563 7.6424 40.3346 14.3157 40.3346H29.6607C36.3341 40.3346 40.3124 36.3563 40.3124 29.683V14.3196C40.3307 7.6463 36.3524 3.66797 29.6791 3.66797ZM32.7224 25.8513C32.6491 26.0163 32.5574 26.163 32.4291 26.2913L26.8557 31.8646C26.5807 32.1396 26.2324 32.268 25.8841 32.268C25.5357 32.268 25.1874 32.1396 24.9124 31.8646C24.3807 31.333 24.3807 30.453 24.9124 29.9213L28.1391 26.6946H12.5557C11.8041 26.6946 11.1807 26.0713 11.1807 25.3196C11.1807 24.568 11.8041 23.9446 12.5557 23.9446H31.4574C31.6407 23.9446 31.8057 23.9813 31.9891 24.0546C32.3191 24.2013 32.5941 24.458 32.7407 24.8063C32.8507 25.1363 32.8507 25.5213 32.7224 25.8513ZM31.4391 20.0396H12.5557C12.3724 20.0396 12.2074 20.003 12.0241 19.9296C11.6941 19.783 11.4191 19.5263 11.2724 19.178C11.1257 18.848 11.1257 18.463 11.2724 18.133C11.3457 17.968 11.4374 17.8213 11.5657 17.693L17.1391 12.1196C17.6707 11.588 18.5507 11.588 19.0824 12.1196C19.6141 12.6513 19.6141 13.5313 19.0824 14.063L15.8741 17.2896H31.4574C32.2091 17.2896 32.8324 17.913 32.8324 18.6646C32.8324 19.4163 32.2091 20.0396 31.4391 20.0396Z"
          fill="url(#paint0_linear_7_212)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7_212"
            x1="21.9883"
            y1="3.66797"
            x2="21.9883"
            y2="44.3266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    id: 6,
    desc: "Business data in DBF, CSV, Other sources",
    icon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8307 14.668H7.33073C5.31406 14.668 3.66406 13.018 3.66406 11.0013V7.33464C3.66406 5.31797 5.31406 3.66797 7.33073 3.66797H12.8307C14.8474 3.66797 16.4974 5.31797 16.4974 7.33464V11.0013C16.4974 13.018 14.8474 14.668 12.8307 14.668Z"
          fill="url(#paint0_linear_7_219)"
        />
        <path
          d="M38.1307 12.8346H31.5307C30.3207 12.8346 29.3307 11.8446 29.3307 10.6346V7.7013C29.3307 6.4913 30.3207 5.5013 31.5307 5.5013H38.1307C39.3407 5.5013 40.3307 6.4913 40.3307 7.7013V10.6346C40.3307 11.8446 39.3407 12.8346 38.1307 12.8346Z"
          fill="url(#paint1_linear_7_219)"
        />
        <path
          d="M38.1307 26.5846H31.5307C30.3207 26.5846 29.3307 25.5946 29.3307 24.3846V21.4513C29.3307 20.2413 30.3207 19.2513 31.5307 19.2513H38.1307C39.3407 19.2513 40.3307 20.2413 40.3307 21.4513V24.3846C40.3307 25.5946 39.3407 26.5846 38.1307 26.5846Z"
          fill="url(#paint2_linear_7_219)"
        />
        <path
          d="M29.3307 24.293C30.0824 24.293 30.7057 23.6696 30.7057 22.918C30.7057 22.1663 30.0824 21.543 29.3307 21.543H24.2891V10.543H29.3307C30.0824 10.543 30.7057 9.91964 30.7057 9.16797C30.7057 8.4163 30.0824 7.79297 29.3307 7.79297H16.4974C15.7457 7.79297 15.1224 8.4163 15.1224 9.16797C15.1224 9.91964 15.7457 10.543 16.4974 10.543H21.5391V33.0013C21.5391 35.788 23.7941 38.043 26.5807 38.043H29.3307C30.0824 38.043 30.7057 37.4196 30.7057 36.668C30.7057 35.9163 30.0824 35.293 29.3307 35.293H26.5807C25.3157 35.293 24.2891 34.2663 24.2891 33.0013V24.293H29.3307Z"
          fill="url(#paint3_linear_7_219)"
        />
        <path
          d="M38.1307 40.3346H31.5307C30.3207 40.3346 29.3307 39.3446 29.3307 38.1346V35.2013C29.3307 33.9913 30.3207 33.0013 31.5307 33.0013H38.1307C39.3407 33.0013 40.3307 33.9913 40.3307 35.2013V38.1346C40.3307 39.3446 39.3407 40.3346 38.1307 40.3346Z"
          fill="url(#paint4_linear_7_219)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7_219"
            x1="34.8359"
            y1="33.001"
            x2="34.8359"
            y2="41.1327"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_7_219"
            x1="34.8359"
            y1="33.001"
            x2="34.8359"
            y2="41.1327"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_7_219"
            x1="34.8359"
            y1="33.001"
            x2="34.8359"
            y2="41.1327"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_7_219"
            x1="34.8359"
            y1="33.001"
            x2="34.8359"
            y2="41.1327"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_7_219"
            x1="34.8359"
            y1="33.001"
            x2="34.8359"
            y2="41.1327"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F26E51" />
            <stop offset="1" stop-color="#FFAD87" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
];
