import React from "react";
import styled from "styled-components";
import contactus from "../../Assets/contactus.png";
import contactmap from "../../Assets/contactmap.png";
import { useSnackbar } from 'react-simple-snackbar'
import { useFormik } from "formik";
import * as Yup from "yup";

const Section = styled.div`
  padding: 0 100px 96px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 0 20px 56px;
  }
`;

const ContainerDiv = styled.div`
  padding-top: 112px;
  padding-bottom: 112px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 40px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Col = styled.div`
  color: #0e1014;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const Col6 = styled(Col)`
  flex: 0 0 50%;
  font-size: 40px;
  font-weight: 300;
  line-height: 56.2px;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const Heading = styled.div`
  font-size: 31px;
  color: #0e1014;
  line-height: 34px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    font-weight: 600;
  }
`;

const InputField = styled.input`
  background: #f2f2f2;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  color: #0e1014;
  font-size: 18px;
  border: 2px solid ${(props) => (props.error ? "red" : "#f2f2f2")};
  width: 100%;
  font-family: "Inter", sans-serif;
  &::placeholder {
    color: #0e1014;
  }
`;

const TextArea = styled.textarea`
  background: #f2f2f2;
  height: 144px;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  color: #0e1014;
  font-size: 18px;
  border: 2px solid ${(props) => (props.error ? "red" : "#f2f2f2")};
  width: 100%;
  font-family: "Inter", sans-serif;
  &::placeholder {
    color: #0e1014;
  }
`;

const Col12 = styled(Col)`
  flex: 0 0 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const Col4 = styled(Col)`
  padding-top: 16px;
  flex: 0 0 33.3333%;
  color: #0e1014;
  font-size: 21px;
  line-height: 31px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    font-size: 11px;
    line-height: 15px;
  }
`;

const Col3 = styled(Col)`
  padding-top: 16px;
  flex: 0 0 25%;
  color: #0e1014;
  font-size: 21px;
  line-height: 31px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    font-size: 11px;
    line-height: 15px;
  }
`;

const Image = styled.img`
  width: 600px;
  height: 456px;
  max-width: 100%;
  max-height: 100%;
  @media screen and (max-width: 800px) {
    width: 300px;
    height: 228px;
    display: none;
  }
`;

const Button = styled.button`
  background: #000;
  color: #fff;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  font-size: 15px;
  letter-spacing: 0.5%;
  width: 121px;
  cursor: pointer;
  border: none;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  padding-top: 0px;
  height: 16px; 
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 4px;
`;


const ContactUsPage = () => {
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, "Invalid phone number")
        .max(10, "Phone number must be exactly 10 characters")
        .nullable(),
      message: Yup.string().required("Message is required").max(100, "Message length should not be more than 100"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-functions-key": "i9XWaTqcnWds9QLkOHchUn67PppW_Hr9_-TR_OA9SfkAAzFuMI3ILw==",
        },
        body: JSON.stringify(values),
      };

      try {
        const response = await fetch("https://virtuosoftemail.azurewebsites.net/api/SendEmail", requestOptions);
        if (!response.ok) {
          throw new Error("Failed to send email");
        }
        resetForm();
        openSnackbar("Email sent successfully!");
        setTimeout(() => {
          closeSnackbar();
        }, 3000);

      } catch (error) {
        console.error("Error:", error.message);
        openSnackbar(error.message);
        setTimeout(() => {
          closeSnackbar();
        }, 3000);
        resetForm();
      }
    },
  });

  return (
    <Section>
      <ContainerDiv>
        <Row>
          <Col6>
            <Heading>CONNECT WITH OUR TEAM</Heading>
            <form style={{ paddingTop: "32px" }} onSubmit={formik.handleSubmit}>
              <div style={{ gap: "12px" }}>
                <InputField
                  placeholder="Your name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
                <ErrorMessage>{formik.touched.name && formik.errors.name ? formik.errors.name : " "}</ErrorMessage>
              </div>
              <div>
                <InputField
                  placeholder="Email address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
                <ErrorMessage>{formik.touched.email && formik.errors.email ? formik.errors.email : " "}</ErrorMessage>
              </div>
              <div>
                <InputField
                  placeholder="Phone number"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
                <ErrorMessage>{formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : " "}</ErrorMessage>
              </div>
              <div>
                <TextArea
                  placeholder="Your message"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.message && formik.errors.message}
                />
                <ErrorMessage style={{ marginTop: "-10px" }}>{formik.touched.message && formik.errors.message ? formik.errors.message : " "}</ErrorMessage>
              </div>
              <div style={{marginTop:"10px"}}>
                <Button type="submit">Submit</Button>
              </div>
            </form>
          </Col6>
          <Col6>
            <Image src={contactus} alt="Contact-us" />
          </Col6>
        </Row>
        <Row style={{ paddingTop: '60px', paddingBottom: '30px' }}>
          <Heading>{"CONTACT"}</Heading>
        </Row>
        <Row>
          <Col4>
            {"Virtuosoft Technologies Private Limited,"}
            <br />
            {"ABE#2, Thejaswini Annexe,"}
            <br />
            {"Technopark Phase 1,Trivandrum, Kerala"}
          </Col4>
          <Col3>
            {"Email"}
            <br />
            {"contactus@virtuosoft.in​"}
          </Col3>
          <Col3>
            {"Linkedin"}
            <br />
            {"Facebook"}
          </Col3>
        </Row>
        <Row>
          <Col12>
            <img
              src={contactmap}
              alt="virtuosoft-map"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                height: "505px",
                width: "100%",
              }}
            />
          </Col12>
        </Row>
      </ContainerDiv>
    </Section>
  );
};

export default ContactUsPage;
