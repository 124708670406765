import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import heroicon from '../../Assets/heroicon.svg';
import image1 from '../../Assets/technology-consulting.png';
import image2 from '../../Assets/business data analytics.png';
import image3 from '../../Assets/clous-section.png';
import { motion } from 'framer-motion';
import Lottie from "lottie-react";
import animationData from "./lottiefile.json";
import customer from './customerslottie.json';
import graph from './graphlottie.json';
import user from '../../Assets/user.png';
import './style.css';


const CenteredText = styled.div`
  color: #0E1014;
  text-align: center;
  font-size: 82px;
  font-style: normal;
  font-weight: 600;
  line-height: 88px;
  letter-spacing: -1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 45px;
  position: relative; /* Add position relative */
  overflow: hidden; /* Hide overflow to prevent scrollbars */

  @media screen and (max-width: 768px) {
    font-size: 37px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
     letter-spacing: -3%;
  }
`;

// const StyledImage = styled.img`
//   width: 64px;
//   height: 64px;
//   @media screen and (max-width: 768px) {
//     width: 22px;
//     height: 22px;
//   }
// `;

const TextWithImage = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImagesRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px; 
  align-items: flex-end;
  width: 100%;
  // padding-top:10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Image1Container = styled.div`
  position:relative;
`;

const Image2Container = styled(motion.div)`
  position:relative;
  overflow:hidden;
  z-index:5;
  height: 360px;
  align-items:flex-end;
  justify-content: flex-end;
  display:flex;
  
  @media screen and (max-width: 768px) {
    height: 220px;
  }
  
`;

const OverlayImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 48px;
`;

const OverlayImage1 = styled.div`
  position: absolute;
  top: -5px;
  left: 41px;
  width: 207px;
  height: 200px;
  @media screen and (max-width: 768px) {
    width: 134px;
    height: 128px;
    top: -1px;
    left: 20px;
  }
`;

const StyledImages1 = styled(motion.img)`
  width: 100%;
  height: 280px;
  max-width:100%;
  max-height:100%;
  justify-content:flex-start;

  @media screen and (max-width: 768px) {
    width: 335px;
    height: 218px;
    max-width:100%;
    max-height:100%;
  }
`;

// const StyledImages2 = styled.img`
//   width: 100%;
//   height: 304px;
//   max-width:100%;
//   max-height:100%;

//   @media screen and (max-width: 768px) {
//     width: 335px;
//     height: 197px;
//     max-width:100%;
//     max-height:100%;
//   }
// `;

const Image3Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-end;
  @media screen and (max-width: 768px) {
    display:none;
  }
`;

const StyledImages3Container = styled.div`
  width: 106px;
  height: 104px;
`;

const StyledImages3 = styled(motion.img)`
  width: 100%;
  height: 175px;
  max-width:100%;
  max-height:100%;

  @media screen and (max-width: 768px) {
    width: 335px;
    height: 218px;
    max-width:100%;
    max-height:100%;
  }
`;


const HeroSection = () => {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [isAnimation1Complete, setIsAnimation1Complete] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimationComplete(true);
    }, 800);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimation1Complete(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imageAnimation = {
    initial: { opacity: 0, y: 60 },
    animate: { opacity: 1, y: 0, transition: { duration: 2, delay: 0.25 } },
  };

  const imageAnimation3 = {
    initial: { opacity: 0, y: 80 },
    animate: { opacity: 1, y: 0, transition: { duration: 2, delay: 0.05 } },
  };

  return (
    <>
      <CenteredText>
        {windowWidth > 768 ?
          <>
            <div className='animated-text1'>
              <div>Empowering our clients to</div>
            </div>
            <TextWithImage className='animated-text2'>
              <span>achieve</span>
              <img src={heroicon} alt="Descriptive Alt Text" />
              <span>their aspirations</span>
            </TextWithImage>
          </>
          :
          <>
            <div className='animated-text1'>
              <div>Empowering our</div>
              <div>clients to achieve</div>
            </div>
            <TextWithImage className='animated-text2'>
              <img src={heroicon} alt="Descriptive Alt Text" />
              <span>their aspirations</span>
            </TextWithImage>
          </>
        }
      </CenteredText>
      <ImagesRow>
        <Image1Container>
          <StyledImages1 variants={imageAnimation} initial="initial" animate="animate" src={image1} alt="Description for Image 1" />
          <OverlayImage>
            {isAnimationComplete && (
              <Lottie
                animationData={customer}
                autoplay={isAnimationComplete}
                loop={false}
              // onComplete={handleAnimationComplete}
              />
            )}
          </OverlayImage>
        </Image1Container>
        <Image2Container variants={imageAnimation3} initial="initial" animate="animate">
          <img className='image-2' src={image2} alt="Hero" />
          <OverlayImage1>
            {isAnimationComplete && (
              <Lottie
                animationData={graph}
                autoplay={isAnimationComplete}
                loop={false}
              // onComplete={handleAnimationComplete}
              />
            )}
          </OverlayImage1>
          {isAnimation1Complete && (
            <img className='styled-image-user' src={user} alt="User" />
          )}
        </Image2Container>
        <Image3Container>
          <StyledImages3Container>
            {isAnimationComplete && (
              <Lottie
                animationData={animationData}
                autoplay={isAnimationComplete}
                loop={false}
              // onComplete={handleAnimationComplete}
              />
            )}
          </StyledImages3Container>
          <StyledImages3 variants={imageAnimation} initial="initial" animate="animate" src={image3} alt="Description for Image 3" className="hide-on-small" />
        </Image3Container>
      </ImagesRow>
    </>
  );
};

export default HeroSection;
