import userimg from '../../Assets/userimg.png';
export const data = [
    {
      id: 1,
      desc: "Virtuosoft's dedication and expertise have truly made a difference.Your innovative approach has provided a solid foundation for the success of the entire project.The streamlined procedure from ticket creation to completion reflects a commendable improvement, and I appreciate the establishment of a standard in this regard. Your insights and efforts in this area are crucial, and I thank you for your valuable contributions",
      icon: (
        <img src={userimg} alt='client' style={{maxHeight:'100%',maxWidth:'100%',width:'56px',height:'56px',borderRadius:'50%'}}/>
      ),
    },
  ];
  